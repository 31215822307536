


$(document).ready(function () {
  $(".first-button").on("click", function () {
    $(".animated-icon1").toggleClass("open");
  });
  $(".second-button").on("click", function () {
    $(".animated-icon2").toggleClass("open");
  });
  $(".third-button").on("click", function () {
    $(".animated-icon3").toggleClass("open");
  });
});


// Function to calculate total price. 
function calc(num, total) {

  numberBooks = document.getElementById(num)
  totalPrice = document.getElementById(total)
  
 
  shipping = 0

  var price = 18.00; 
  var numBooks = numberBooks.value;
  var total = (numBooks * parseFloat(price));

  if (!isNaN(total)) totalPrice.innerHTML = total.toFixed(2);
};

//Calculate group total on page load

$(document).ready(function () {
  calc("groupNumberBooks", "groupTotal")
});

// calculate total price when number of books field is updated for school/group purchases
$(document).ready(function () {
  $("#groupNumberBooks").on("input", function () {
    calc("groupNumberBooks", "groupTotal")
  });
});

// Calculate individual total on page load

$(document).ready(function () {
  calc("individualNumberBooks", "individualTotal");
});

// calculate total price when number of books field is updated for individual purchases
$(document).ready(function () {
  $("#individualNumberBooks").on("input", function () {
    calc("individualNumberBooks", "individualTotal")
  });
});

