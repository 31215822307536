//= require jquery
//= require jquery_ujs
//= require rails_ujs
//= require turbolinks
//= require bootstrap
//= require order
//= require individualOrder
//= require_tree .
//= require_directory .

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("custom/application")

import "bootstrap";



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


